@font-face {
  font-family: Rubik;
  src: url(./fonts/Rubik-Regular.ttf);
}
*{
    font-family: "Rubik","Helvetica","Arial",sans-serif;
    font-size: 18px;
}
body{
    margin: 0;
    padding-bottom: 10px;
}
.content-wrapper{
    margin: 10px auto;
    max-width: 1000px;
}
.customer-information-popup .content-wrapper.MuiContainer-root{
  max-width: none;
  padding: 0 15px 15px 15px;
}
.customer-information-popup .content-wrapper .MuiDataGrid-root{
  border: 0;
}
.customer-information-popup .content-wrapper .MuiDataGrid-columnSeparator{
  display: none;
}
.customer-information-view .device-wrapper{
  display: flex;
  padding-right: 13px;
}
.customer-information-view .column .item{
  display: flex;
  margin: 9px 0;
}
.customer-information-view .column.right{
  width: 50%;
}
.customer-information-view .column .item .field{
  margin-left: 5px;
}
.customer-information-view .column.right .item{
  display: flex;
}
label.file-uploader{
    margin-top: 20px;
    border: 2px dashed;
    font-size: 18px;
    min-width: fit-content;
}
label.file-uploader span{
    font-size: 18px;
}
label.file-uploader svg{
    display: none;
}
.hidden{
    display: none;
}
.success-message{
    border: 1px solid green;
    background-color: #d0fdd0;
    padding: 10px;
    margin-bottom: 20px;
    color: green;
}
.notice-message, .warning-message{
    border: 1px solid rgb(148, 60, 1);
    background-color: #FFF1DD;
    padding: 9px;
    margin-bottom: 20px;
    color: rgb(148, 60, 1);
    border-radius: 21px;
    display: flex;
    justify-content: space-between;
}
.notice-message.categories{
  margin: 23px 50px 23px 30px;
}
.error-message{
    border: 1px solid rgb(255, 30, 0);
    background-color: #ffb6ac;
    padding: 10px;
    margin-bottom: 20px;
    color: rgb(148, 1, 1);
}
.dialog-title{
    background-color: #000;
    color: #fff;
}
div.content-wrapper .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell,
.css-etrw2r-MuiDataGrid-root .MuiDataGrid-cellContent{
    white-space: normal;
    word-break: break-word;
}
div.content-wrapper .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell[data-field="number"] div{
    direction: ltr;
}
.MuiContainer-root.content-wrapper{
    height: 50vh;
}

div.content-wrapper .MuiDataGrid-cellContent{
    white-space: normal;
    word-break: break-word;
}
.check-wrapper{
    background: #00b7ea; /* Old browsers */
    background: -moz-linear-gradient(-45deg, #00b7ea 0%, #009ec3 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #00b7ea 0%,#009ec3 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #00b7ea 0%,#009ec3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00b7ea', endColorstr='#009ec3',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    padding: 3px 9px 1px;
    border-radius: 20px;
    width: fit-content;
}
.MuiDialog-container .check-wrapper{
    padding: 3px 9px 3px;
}
.check{
    display: inline-block;
    transform: rotate(45deg);
    height: 9px;
    width: 4px;
    border-bottom: 4px solid #fff;
    border-right: 4px solid #fff;
}
div.content-wrapper .MuiDataGrid-cell[data-field="delete"]{
    justify-content: center;
}
.MuiDataGrid-cell[data-field="delete"] svg{
    cursor: pointer;
}
.MuiDataGrid-cell[data-field="delete"] svg.locked{
    cursor: unset;
}
.MuiDataGrid-cell[data-field="delete"] svg.locked path{
    fill: lightgray;
}
.clock-day-wrapper{
    margin-bottom: 20px;
}
.clock-day-title{
    margin-bottom: 10px;
    cursor: pointer;
}
.clock-day-title div{
    margin-left: 5px;
}
.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  height: 1px;
  width: 1px;
}

.arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.day-name{
  width: 100px;
}
.watch-wrapper{
  display: flex;
}
.watch-wrapper .excluded-numbers-wrapper{
  background-color: #F5F5F5;
  padding: 40px 32px;
}
.watch-wrapper .add-input-wrapper{
  display: flex;
  width: 160px;
  align-items: center;
  background-color: white;
  border-radius: 22px;
  padding: 0 9px;
}
.watch-wrapper .add-input-wrapper input{
  width: 90%;
  margin-bottom: 5px;
  margin-left: 5px;
}
.white-plus-in-yellow{
  background-color: #FFC700;
  border-radius: 21px;
  padding: 3px;
  height: fit-content;
  display: block;
  cursor: pointer;
}
.simehadrin .white-plus-in-yellow{
  background-color: #206881;
}
.watch-wrapper .excluded-numbers-wrapper .add-button{
  background-color: white;
  width: fit-content;
  padding: 3px 14px;
  border-radius: 21px;
  cursor: pointer;
}
.watch-wrapper .watch-content-wrapper{
  width: calc(80% - 240px);
  padding: 95px 120px;
  position: relative;
}
.watch-wrapper .saved-message{
  background-color: #FFC700;
  padding: 4px 12px 6px 12px;
  border-radius: 21px;
  display: flex;
  position: sticky;
  top: 40px;
}
.simehadrin .watch-wrapper .saved-message{
  background-color: #206881;
}
.excluded-numbers-slider{
  position: fixed;
  bottom: 0;
  padding: 8px 0 12px 0;
  border-radius: 23px 23px 0 0;
  filter: drop-shadow(1px -1px 10px rgba(0, 0, 0, 0.15));
  background-color: #A0A0A0;
  width: 100%;
}
.horizontal-line{
  width: 51px;
  height: 2px;
  background-color: white;
  margin: 0 auto 5px;
}
.excluded-numbers-wrapper .horizontal-line{
  background-color: #DDDDDD;
}
.excluded-numbers-wrapper .title{
  margin: auto;
}
.excluded-numbers-wrapper .top-wrapper{
  width: 100%;
  height: 10px;
}
.excluded-numbers-slider .text{
  color: white;
  width: fit-content;
  margin: auto;
}
.mobile.watch-wrapper .saved-message{
    background-color: white;
    border: 4px solid #FFC700;
    padding: 2px 12px 4px 12px;
}
.simehadrin .mobile.watch-wrapper .saved-message{
  border-color: #206881;
}
.watch-content .success-message-wrapper{
  width: 160px;
}
.watch-wrapper .saved-message .text{
  margin-left: 6px;
}
.watch-wrapper .saved-message img,
.watch-wrapper .saved-message svg{
  background-color: black;
  border-radius: 20px;
  padding: 5px;
}
.mobile.watch-wrapper .saved-message svg path{
  stroke: white;
}
.watch-wrapper .watch-content{
  display: flex;
  justify-content: space-between;
}
.watch-wrapper .excluded-numbers-wrapper{
  width: calc(20% - 64px);
}
.watch-wrapper .excluded-numbers-wrapper .title{
  margin-bottom: 20px;
}
.watch-content-wrapper .status-wrapper{
  display: flex;
  align-items: center;
  height: fit-content;
  justify-content: space-between;
  margin-bottom: 10px;
}
.watch-content-wrapper .status-wrapper .title{
  font-weight: bold;
  margin-left: 10px;
}
.watch-content-wrapper .values-wrapper{
  display: flex;
  background: #FFC700;
  border-radius: 21px;    
  height: fit-content;
  padding: 6px;
}
.simehadrin .watch-content-wrapper .values-wrapper{
  background-color: #206881;
}
.apply-watch-settings div[role="presentation"] .MuiPaper-root{
  width: 260px;
}
.apply-watch-settings .days-wrapper{
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: #F5F5F5;
  border-radius: 21px;
  margin-bottom: 25px;
}
.apply-watch-settings .days-wrapper .day{
  padding: 5px 11px;
  cursor: pointer;
}
.apply-watch-settings .days-wrapper .day.selected{
  background-color: #FFC700;
  border-radius: 21px;
}
.simehadrin .apply-watch-settings .days-wrapper .day.selected{
  background-color: #206881;
}
.watch-content-wrapper .values-wrapper .value{
  color: white;
  border-radius: 21px;
  padding: 3px 8px;
  cursor: pointer;
}
.watch-content-wrapper .values-wrapper .value.selected{
  background-color: white;
  color: black;
}
.watch-content-wrapper .profile-wrapper .days-title{
  display: flex;
  margin-bottom: 17px;
}
.watch-content-wrapper .profile-wrapper .days-title .days{
  display: flex;
  background: #F5F5F5;
  border-radius: 21px;
  align-items: center;
  width: 100%;
}
.watch-content-wrapper .profile-wrapper .day-profile{
  display: flex;
  justify-content: space-between;
}
.watch-content-wrapper .profile-wrapper{
  display: flex;
  align-items: flex-start;
}
.watch-content-wrapper .profile-wrapper .profile-content{
  margin-left: 15px;
}
.watch-content-wrapper .profile-wrapper .profile-content-wrapper .overlay{
  background-color: rgba(255, 255, 255, 0.72);
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
}
.watch-content-wrapper .profile-wrapper .profile-content-wrapper{
  position: relative;
}
.watch-content-wrapper .profile-wrapper .day-profile .column{
  width: calc(50% - 4px);
}
.watch-content-wrapper .profile-wrapper .day-profile .column.right{
  width: calc(50% - 4px);
  margin-left: 8px;
}
.watch-wrapper .switch-type{
  width: 52px;
  background-color: #C6C6C6;
  color: white;
  justify-content: space-between;
  margin: 0;
}
.watch-wrapper .switch-type.main{
  background-color: white;
  color: #C6C6C6;
}
.watch-wrapper .switch-type.main circle{
  fill: #C6C6C6;
}
.watch-content-wrapper .profile-wrapper .day-profile .hour-wrapper{
  display: flex;
  background: #F5F5F5;
  border-radius: 21px;
  padding: 7px 10px 5px 7px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
.watch-wrapper .select .lines-list{
  background-color: white;
  padding: 10px;
  border-radius: 14px;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 25%);
  top: -9px;
  right: -8px;
  z-index: 999;
  color: #888888;
}
.watch-wrapper .select .lines-list .arrow{
  margin-top: 7px;
}
.watch-wrapper .select .lines-list .selected{
  color: black;
}
.watch-wrapper.mobile .select .lines-list .selected{
  color: #FFC700;
}
.simehadrin .watch-wrapper.mobile .select .lines-list .selected{
  color: #206881;
}
.watch-wrapper .select{
  width: 65px;
  justify-content: space-between;
  margin-top: -3px;
}
.watch-content-wrapper .profile-wrapper .days-title img{
  cursor: pointer;
}
.watch-content-wrapper .profile-wrapper .days-title .days .day{
  padding: 10px 20px;
  font-weight: bold;
  cursor: pointer;
}
.watch-content-wrapper .profile-wrapper .days-title .days .day.selected{
  background: #FFC700;
  border-radius: 21px;
}
.simehadrin .watch-content-wrapper .profile-wrapper .days-title .days .day.selected{
  background-color: #206881;
}
.user-account-wrapper{
  max-width: 1000px;
  margin: auto;
}
.user-account-wrapper .label{
  width: 200px;
}
.user-account-wrapper .label.intend{
  width: 157px;
  margin-right: 10px;
}
.user-account-wrapper .label.main,
.user-account-wrapper .block-list .field-wrapper{
  margin-bottom: 10px;
}
.login-form-wrapper .logo{
  display: block;
  width: 200px;
}
.simehadrin .login-form-wrapper .logo{
  margin-bottom: 60px;
}
.login-decoration .logo{
  width: 260px;
}
.login-decoration *{
  margin: 0 auto;
}
.login-decoration .main-title{
  font-size: 32px;
  width: fit-content;
}
.login-form-wrapper,
.login-decoration-wrapper{
  width: 50%;
}
.login-decoration-wrapper{
  background-color: #FFC700;
  min-height: 100vh;
}
.simehadrin .login-decoration-wrapper{
  background-color: #206881;
  min-height: 155vh;
}
.login-form{
  width: 384px;
  margin: 120px auto;
}
.login-form form{
  margin-bottom: 27px;
}
.login-decoration{
  width: fit-content;
  margin: 300px auto;
}
.login-form *{
  margin: 0 auto;
}
.login-form .main-title{
  width: fit-content;
  font-size: 28px;
  margin-bottom: 22px;
}
.login-form .sub-title{
  font-weight: 400;
  color: #979797;
  font-size: 18px;
  margin-bottom: 37px;
  width: fit-content;
}
.simehadrin .login-form .sub-title{
  margin-bottom: 20px;
}
.login-form .input,
.login-form .input div{
  width: 100%;
}
.login-form .input-wrapper{
  margin-bottom: 25px;
}
.login-form .input-wrapper p{
  margin-bottom: 9px;
}
.login-form button{
  width: 100%;
  background-color: #FFC700;
  color: black;
  box-shadow: none;
  border-radius: 8px;
  border: 0;
}
.simehadrin .login-form button{
  background-color: #206881;
}
.login-form button:hover{
  background-color: #FFC700;
}
.simehadrin .login-form button:hover{
  background-color: #206881;
}
.login-form input{
  border: 1px solid #DCDCDC;
  outline-color: #FFC700;
  border-radius: 8px;
}
.simehadrin .login-form input{
  outline-color: #206881;
}
.login-form input:hover{
  border: 1px solid #FFC700;
  outline-color: #FFC700 !important;
}
.simehadrin .login-form input:hover{
  border: 1px solid #206881;
  outline-color: #206881 !important;
}
.login-page-decoration{
  min-height: 344px;
  width: 100%;
  background-repeat: no-repeat;
  position: absolute;
  background-position: center 0;
  margin: 0;
  padding: 0;
  top: 0;
}
.login-error{
  border: 1px solid #FFAEA8;
  background-color: #FFFBFA;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  color: #B42318;
  align-items: flex-start;
}
.login-error .alert{
  border: 2px solid #B42318;
  padding: 1px 8px 0px 8px;
  border-radius: 17px;
  font-weight: bold;
  height: 20px;
}
.login-error .text{
  margin-right: 5px;
  margin-left: 30px;
}
.login-error .close{
  margin-top: 5px;
  cursor: pointer;
}
.login-error .message{
  font-weight: bold;
  margin-bottom: 7px;
}
.chips{
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
}
.chip{
  display: flex;
  background-color: white;
  width: fit-content;
  padding: 4px 8px;
  border-radius: 21px;
  width: 120px;
  justify-content: space-between;
  margin-left: 8px;
  margin-bottom: 8px;
  height: fit-content;
}
.chip .delete{
  cursor: pointer;
  width: 19px;
}
.chip .text{
  color: #888888;
  margin-left: 6px;
  width: 90%;
  word-break: break-all;
}
.watch-status-wrapper,
.watch-days-wrapper{
  border: 1px solid #dfdede;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 20px;
}
.dashboard-wrapper{
  display: flex;
  min-height: 80vh;
}
.dashboard-wrapper .side,
.dashboard-wrapper .main .stats-wrapper{
  background-color: #F5F5F5;
  width: 116px;
  padding: 40px 117px;
}
.dashboard-wrapper .stats .number{
  font-size: 57px;
  color: #FFC700;
  width: fit-content;
  margin: auto;
  font-weight: bold;
}
.simehadrin .dashboard-wrapper .stats .number{
  color: #206881;
}
.dashboard-wrapper .stats .text{
  width: fit-content;
  margin: auto;
}
.dashboard-wrapper .stats .text .type{
  margin-left: 5px;
  font-weight: bold;
}
.dashboard-wrapper .stats{
  margin-bottom: 33px;
}
.dashboard-wrapper .description{
  margin: 51px 45px;
}
.dashboard-wrapper .blocking-policy-wrapper .title{
  font-weight: bold;
  margin-bottom: 13px;
}
.dashboard-wrapper .blocking-policy-wrapper{
  margin: 57px 45px;
}
.dashboard-wrapper .blocking-policy{
  margin-bottom: 13px;
}
.dashboard-wrapper .blocking-policy .switch.blacklist{
  background-color: #A0A0A0;
}
.dashboard-wrapper .blocking-policy .switch.blacklist .text{
  margin-left: 5px;
}
.dashboard-wrapper .blocking-policy .switch.whitelist{
  background-color: #FFC700;
}
.simehadrin .dashboard-wrapper .blocking-policy .switch.whitelist{
  background-color: #206881;
}
.dashboard-wrapper .blocking-policy .switch.whitelist .text{
  margin-right: 5px;
}
.dashboard-wrapper .blocking-policy .switch{
  display: flex;
  width: 142px;
  border-radius: 32px;
  color: #fff;
  padding: 3px;
  font-size: 18px;
  line-height: 24px;
  justify-content: space-evenly;
}
.dashboard-wrapper .blocking-policy{
  display: flex;
  align-items: center;
}
.dashboard-wrapper .blocking-policy .type{
  margin-left: 15px;
  width: 100px;
}
.dashboard-wrapper .main .stats-wrapper{
  display: none;
  width: calc( 100% - 234px );
}
.anti-spam-wrapper .setting-wrapper{
  margin-bottom: 30px;
}
.anti-spam-wrapper .setting-wrapper .content{
  margin: 20px 0;
}
.anti-spam-wrapper .note{
  margin-bottom: 10px;
  font-size: 15px;
}
.MuiMenu-paper.MuiPopover-paper {
  max-height: 300px;
}
.customers-wrapper, .anti-spam-wrapper{
  padding: 40px 0;
  width: 90%;
  margin: auto;
}
.customers-wrapper .grid{
  min-height: 80vh;
  border-radius: 21px;
  background-color: #F5F5F5;
  margin-left: 66px;
}
.customers-wrapper .grid-wrapper{
  display: flex;
}
.customers-wrapper .title{
  font-weight: bold;
  margin-bottom: 18px;
}
.customers-wrapper .search-wrapper,
.category-information-mobile .search-wrapper,
.customer-blocks-information-view .search-wrapper{
  height: fit-content;
  border-radius: 21px;
  background-color: #F5F5F5;
  padding: 0 20px;
  display: flex;
  align-items: center;
  width: 30%;
}
.customers-wrapper .search-wrapper .field,
.customer-blocks-information-view .search-wrapper .field{
  min-width: 115px;
  width: 30%;
}
.customers-wrapper .search-wrapper .search,
.category-information-mobile .search-wrapper .search,
.customer-blocks-information-view .search-wrapper .search{
  border: 0;
  border-bottom: 0.5px solid #000000;
  background-color: #F5F5F5;
  display: block;
  margin-left: 12px;
  width: 65%;
}
.category-information-mobile .search-wrapper .search{
  width: calc(100% - 38px);
}
.customer-blocks-information-view .search-wrapper .search{
  width: 90%;
  margin-left: 6px;
}
.customer-blocks-information-view .search-wrapper .button{
  width: 26px;
}
.customer-blocks-information-view .switch-wrapper{
  margin-bottom: 16px;
}
.customers-wrapper .grid .button{
  display: block;
  cursor: pointer;
}
.customer-information-popup .settings-dropdown .main{
  display: flex;
  background-color: white;
  border-radius: 15px;
  padding: 3px 9px;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
}
.customer-information-popup{
  margin-top: 10px;
}
.customer-information-popup .settings-dropdown .list{
  background-color: black;
  color: white;
  border-radius: 15px;
  position: absolute;
  z-index: 999;
  top: 0;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25)
}
.customer-information-popup .settings-dropdown{
  position: relative;
  width: 150px;
}
.customer-information-popup .settings-dropdown .list .item{
  display: flex;
  justify-content: space-between;
  padding: 5px 9px;
  cursor: pointer;
}
.customer-information-popup .settings-dropdown .list .item .text{
  margin-left: 8px;
}
.customer-information-popup .settings-dropdown .list .item.first path{
  fill: #FFC700;
}
.simehadrin .customer-information-popup .settings-dropdown .list .item.first path{
  fill: #206881;
}
.customer-information-popup .settings-dropdown .list .item.first{
  color: #FFC700;
}
.simehadrin .customer-information-popup .settings-dropdown .list .item.first{
  color: #206881;
}
.customer-information-popup .settings-dropdown .main:hover{
  background-color: black;
  color: #FFC700;
}
.simehadrin .customer-information-popup .settings-dropdown .main:hover{
  color: #206881;
}
.customer-information-popup .settings-dropdown .main:hover svg path{
  fill: #FFC700;
}
.simehadrin .customer-information-popup .settings-dropdown .main:hover svg path{
  fill: #206881;
}
.customer-information-popup .settings-dropdown .main .text{
  margin-left: 8px;
}
.customer-information-popup .button{
  cursor: pointer;
}
.customers-wrapper .grid .button:hover ellipse{
  fill: black;
}
.customer-information-popup .button:hover ellipse{
  fill: #FFC700;
}
.simehadrin .customer-information-popup .button:hover ellipse{
  fill: #206881;
}
.customer-information-popup .button.show-device:hover path{
  fill: black;
}
.customer-information-popup .button.show-device:hover circle{
  stroke: #FFC700;
}
.simehadrin .customer-information-popup .button.show-device:hover circle{
  stroke: #206881;
}
.customer-information-popup .settings img{
  width: 29px;
  height: 29px;
}
.customer-information-popup .settings .list .item{
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.customer-information-popup .settings .button:hover circle,
.customer-information-popup .settings.opened .button circle{
  fill: #FFC700;
}
.simehadrin .customer-information-popup .settings .button:hover circle,
.simehadrin .customer-information-popup .settings.opened .button circle{
  fill: #206881;
}
.customer-information-popup .settings .button:hover path,
.customer-information-popup .settings.opened .button path{
  fill: black;
}
.customer-information-popup div[data-field="view_details"],
.customer-information-popup div[data-field="view_watch_profile"]{
  display: flex;
  justify-content: center;
}
.customers-wrapper .grid .button:hover circle{
  stroke: black;
}
.customer-information-view .back .arrow-around,
.customer-blocks-information-view .back .arrow-around{
  margin-left: 8px;
}
.customer-information-view,
.customer-blocks-information-view{
  padding: 24px 17px;
}
.customer-information-view .back,
.customer-blocks-information-view .back{
  display: flex;
  margin-bottom: 14px;
}
.customer-information-view .customer-info .item{
  margin-bottom: 6px;
}
.customer-information-view .customer-info .item:last-child{
  margin-bottom: 23px;
}
.customer-information-view .title .text.main,
.customer-blocks-information-view .title .text.main{
  font-weight: bold;
  margin-bottom: 13px;
}
.grid .MuiDataGrid-columnHeader[data-field="view"] .MuiDataGrid-columnSeparator,
.grid .MuiDataGrid-columnHeader[data-field="view"] .MuiDataGrid-menuIcon,
.grid .MuiDataGrid-columnHeader[data-field="delete"] .MuiDataGrid-columnSeparator,
.grid .MuiDataGrid-columnHeader[data-field="delete"] .MuiDataGrid-menuIcon{
  display: none;
}
.grid  .MuiDataGrid-cellContent,
.grid .MuiDataGrid-columnHeaderTitle{
  white-space: normal;
  word-break: break-word;
  line-height: 22px;
}
.mobile-grid .header,
.mobile-grid .body .row{
  display: flex;
  border-bottom: 1.5px solid #DDDDDD;
}
.mobile-grid .no-rows{
  position: relative;
  right: -60px;
  background-color: #F5F5F5;
  padding: 10px;
}
.mobile-grid .body .row.expanded{
  margin-bottom: 30px;
}
.customer-information-view .mobile-grid .body .row.expanded{
  margin-bottom: 100px;
}
.mobile-grid .body .row:last-child{
  border: 0;
}
.mobile-grid .expansion .main{
  display: flex;
  background-color: #FFC700;
  border-radius: 20px;
  align-items: center;
}
.simehadrin .mobile-grid .expansion .main{
  background-color: #206881;
}
.categories-wrapper .mobile-grid .expansion .main{
  padding: 0 13px;
  justify-content: space-between;
}
.categories-wrapper .mobile-grid .expansion .controls .item{
  display: flex;
  width: fit-content;
}
.categories-wrapper .mobile-grid .expansion .info{
  padding: 7px 13px;
}
.categories-wrapper .mobile-grid .expansion .info .line{
  display: flex;
}
.categories-wrapper .mobile-grid .expansion .info .line .label,
.categories-wrapper .mobile-grid .expansion .info .line .value{
  width: 50%;
}
.categories-wrapper .mobile-grid .expansion .controls .separator{
  padding: 7px 0;
  border-left: 1px solid white;
  width: fit-content;
}
.categories-wrapper .mobile-grid-wrapper .controls .pagination{
  padding: unset;
  margin: 10px;
}
.categories-wrapper .mobile-grid .body .row.expanded{
  margin-bottom: 18px;
}
.categories-wrapper .mobile-grid .expansion .controls .item img,
.categories-wrapper .mobile-grid .expansion .controls .item svg{
  margin-left: 3px;
}
.simehadrin .categories-wrapper .mobile-grid .expansion .controls .item svg path.external{
  fill: gray;
}
.simehadrin .categories-wrapper .mobile-grid .expansion .controls .item svg circle{
  stroke: gray;
}
.categories-wrapper .mobile-grid .expansion .controls .item .text{
  color: white;
}
.mobile-grid .expansion .main .item{
  padding: 7px 12px;
  width: 50%;
}
.mobile-grid .expansion .device-info-button{
  background-color: #DDDDDD;
  margin-top: 11px;
  padding: 5px 15px;
  border-radius: 14px;
}
.mobile-grid .expansion .device-info-button.collapsed{
  width: fit-content;
  border-radius: 17px 0;
}
.mobile-grid .expansion .general-info .item{
  display: flex;
}
.mobile-grid .expansion .general-info .item .field,
.mobile-grid .expansion .general-info .item .value{
  width: 50%;
  padding: 7px 12px 0;
}
.mobile-grid .expansion .controls{
  margin: 7px 13px;
}
.mobile-grid .expansion .main .controls{
  margin: unset;
}
.mobile-grid .expansion .controls .item{
  width: 33%;
  text-align: center;
  border-left: 2px solid #FFC700;
}
.simehadrin .mobile-grid .expansion .controls .item{
  border-left: 2px solid #206881;
}
.mobile-grid .expansion .controls .item:last-child{
  border: none;
}
.mobile-grid .expansion{
  border: 1.5px solid #FFC700;
  border-radius: 17px;
  background-color: #F5F5F5;
  position: absolute;
  width: inherit;
}
.simehadrin .mobile-grid .expansion{
  border: 1.5px solid #206881;
}
.mobile-grid-wrapper{
  position: relative;
}
.mobile-grid-wrapper .expansions-wrapper{
  position: absolute;
  width: 100%;
  top: 33px;
}
.mobile-grid .header .column,
.mobile-grid .body .row .item{
  padding: 7px 13px;
  white-space: nowrap;
  overflow-x: scroll;
  min-height: 22px;
}
.mobile-grid{
  background-color: #F5F5F5;
  border-radius: 20px;
  display: flex;
}
.mobile-grid .main-column,
.mobile-grid .extra-columns{
  width: 50%;
}
.mobile-grid .main-column{
  border-radius: 20px;
  box-shadow: -1px 0px 4px rgb(0 0 0 / 20%);
}
.grid-wrapper .search-wrapper .MuiOutlinedInput-root.MuiInputBase-root div[role="button"]{
  padding-left: 32px;
  padding-right: 14px;
}
.grid-wrapper .search-wrapper .MuiOutlinedInput-root.MuiInputBase-root svg{
  left: 7px;
  right: unset;
}
.columns-swap .button{
  width: 9px;
  height: 9px;
  background-color: #C6C6C6;
  border-radius: 5px;
  margin-right: 8px;
}
.columns-swap .button.current{
  background-color: black;
}
.columns-swap{
  display: flex;
  justify-content: center;
}
.mobile-grid-wrapper .controls{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mobile-grid-wrapper .controls .pagination{
  width: 50%;
  padding: 15px 30px 0 0;
  display: flex;
  align-items: center;
}
.mobile-grid-wrapper .controls .pagination .page-info{
  margin: 0 10px;
}
.pagination .page-arrow{
  cursor: pointer;
}
.mobile-grid-wrapper .columns-swap{
  width: 50%;
}
.grid .MuiTablePagination-actions{
  display: flex;
  flex-direction: row-reverse;
}
div[role="presentation"] .MuiBackdrop-root{
  background-color: rgba(255, 255, 255, 0.75);
}
div[role="presentation"] .MuiPaper-root{
  border-radius: 22px;
  box-shadow: none;
  border: 1px solid #FFC700;
  padding: 23px;
}
.simehadrin [role="presentation"] .MuiPaper-root{
  border: 1px solid #206881;
}
.liability-popup div[role="presentation"] .MuiPaper-root{
  border: 1px solid #A44200;
  background-color: #FFF1DD;
  color: #A44200;
}
.popup-content .controls{
  display: flex;
  justify-content: space-between;
}
.popup-content .text{
  margin-bottom: 6px;
}
.popup-content .controls .proceed,
.popup-content .controls .cancel{
  width: 87px;
  border: 1px solid #FFC700;
  border-radius: 32px;
  text-align: center;
  padding: 2px 0;
}
.simehadrin .popup-content .controls .proceed,
.simehadrin .popup-content .controls .cancel{
  border: 1px solid #206881;
}
.popup-content .controls .proceed{
  color: #FFC700;
}
.simehadrin .popup-content .controls .proceed{
  color: #206881;
}
.popup-content .controls .cancel{
  color: white;
  background-color: #FFC700;
}
.simehadrin .popup-content .controls .cancel{
  background-color: #206881;
}
.customer-information-popup .title .text.main{
  font-weight: bold;
}
.customer-information-popup .desktop-grid .item .settings .button{
  display: flex;
  justify-content: center;
  padding: 4px;
  position: absolute;
  top: -18px;
}
.customer-information-popup .desktop-grid .item .settings .list .item{
  display: flex;
  width: 100%;
  margin-bottom: 8px;
}
.customer-information-popup .desktop-grid .item .settings .list .item:last-child{
  margin-bottom: 0;
}
.customer-information-popup .desktop-grid .item .settings .list{
  width: 142px;
  position: absolute;
  left: 0px;
  background-color: #FFC700;
  padding: 5px;
  border-radius: 0 15px 15px 15px;
  display: none;
  top: 23px;
  left: -37px;  
}
.simehadrin .customer-information-popup .desktop-grid .item .settings .list{
  background-color: #206881; 
}
.back{
  cursor: pointer;
}
.button{
  cursor: pointer;
}
.customer-information-popup .desktop-grid .item .settings{
  position: relative;
}
.customer-information-popup .desktop-grid .item .settings.opened .button{
  background-color: #FFC700;
  border-radius: 15px 15px 0 0;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 25%);
  z-index: 1001;
  position: absolute;
}
.simehadrin .customer-information-popup .desktop-grid .item .settings.opened .button{
  background-color: #206881;
}
.customer-information-popup .desktop-grid .item .settings.opened .button:after{
  background: #FFC700;
}
.simehadrin .customer-information-popup .desktop-grid .item .settings.opened .button:after{
  background: #206881;
}
.customer-information-popup .desktop-grid .item .settings.opened .list{
  box-shadow: 0px 2px 8px rgb(0 0 0 / 25%);
  display: block;
  z-index: 999;
  left: -37px;
}
.customer-information-popup .title{
  background-color: #FFC700;
  position: relative;
  padding: 10px 15px 20px;
  border-radius: 21.5px 21.5px 0px 0px;
}
.simehadrin .customer-information-popup .title{
  background-color: #206881;
}
.customer-information-popup .desktop-grid-wrapper{
  background-color: #F5F5F5;
  border-radius: 21px;
}
.customer-information-popup .desktop-grid-wrapper .controls{
  padding: 20px 15px;
}
.customer-information-popup .desktop-grid{
  border-radius: 0 0 21px 21px;
}
.customer-information-popup .MuiPaper-root{
  box-shadow: none;
}
.customer-information-popup .MuiBox-root{
  display: block;
}
.customer-information-popup .top-controls .back .text{
  color: white;
  margin-left: 10px;
  width: 170px;
}
.customer-information-popup .top-controls,
.customer-information-popup .bottom-controls{
  display: flex;
  justify-content: space-between;
}
.customer-information-popup .top-controls .back{
  display: flex;
}
.customer-information-popup .top-controls{
  margin-bottom: 19px;
}
.customer-information-popup .bottom-controls{
  margin-bottom: 12px;
}
.customer-information-popup .bottom-controls .customer-info{
  display: flex;
  justify-content: space-between;
  width: 80%;
  align-items: flex-end;
}
.customer-information-popup .bottom-controls button{
  color: #FFC700;
  background-color: white;
  border-radius: 21px;
  padding: 0 10px;
  box-shadow: none;
  width: 100%;
  font-size: 18px;
}
.simehadrin .customer-information-popup .bottom-controls button{
  color: #206881;
}
.customer-information-popup .top-round-corners{
  position: absolute;
  width: 100%;
  height: 20px;
  left: 0;
  bottom: -1px;
  background-color: #F5F5F5;
  border-radius: 21px 21px 0 0;
}
.customer-information-popup .MuiDialogContent-root{
  background-color: #F5F5F5;
}
.customer-wrapper .MuiDialogContent-root{
  padding: 0;
}
.customer-wrapper .MuiPaper-root{
  background: none;
}
.popup .title{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.popup .content > .text{
  margin-bottom: 10px;
}
.field-note{
  color: #BABABA;
}
.field-note.error{
  color: red;
}
.remove-customer-popup .note-wrapper > .text{
  color: #FFC700;
}
.simehadrin .remove-customer-popup .note-wrapper > .text{
  color: #206881;
}
.remove-customer-popup .note-wrapper{
  margin-bottom: 22px;
}
.popup .controls{
  display: flex;
  justify-content: space-between;
}
.liability-popup .controls{
  justify-content: flex-end;
}
.liability-popup .controls .proceed{
  background-color: #A44200;
  color: white;
  padding: 3px 20px;
  width: unset;
}
.notice-message .main{
  font-weight: bold;
}
.popup .controls div{
  border-radius: 32px;
    width: 150px;
    text-align: center;
}
.popup .controls .proceed{
  margin-left: 10px;
  cursor: pointer;
}
.popup .controls .cancel{
  cursor: pointer;
}
.remove-customer-popup .controls .proceed,
.apply-watch-settings .controls .proceed{
  background-color: #FFC700;
  color: white;
}
.simehadrin .remove-customer-popup .controls .proceed,
.simehadrin .apply-watch-settings .controls .proceed{
  background-color: #206881;
}
.remove-customer-popup .controls .cancel,
.apply-watch-settings .controls .cancel{
  border: 1px solid #FFC700;
  color: #FFC700;
}
.simehadrin .remove-customer-popup .controls .cancel,
.simehadrin .apply-watch-settings .controls .cancel{
  border: 1px solid #206881;
  color: #206881;
}
.remove-customer-popup .controls .cancel,
.remove-customer-popup .controls .proceed{
  padding: 3px 0;
}
.remove-customer-popup .note-wrapper input{
  width: 100%;
}
.popup .title .text-wrapper{
  display: flex;
  align-items: center;
}
.popup .title .text-wrapper svg{
  margin-left: 10px;
}
.simehadrin .popup .title .text-wrapper svg path{
  stroke: #206881;
}
.popup .title .text-wrapper .text{
  font-weight: bold;
}
.customer-blocks-popup{
  padding: 10px;
}
.customer-blocks-popup .blocks-list-wrapper .controls-wrapper,
.customer-watch-wrapper .controls-wrapper{
  background-color: #888888;
  border-radius: 21px;
  padding: 10px 15px 32px;
  display: flex;
  justify-content: space-between;
}
.customer-watch-wrapper .controls-wrapper{
  padding-bottom: 55px;
}
.customer-watch-wrapper .watch-content-wrapper{
  border-radius: 0 21px 0 0;
  background-color: white;
  padding: 40px 120px;
}
.customer-watch-wrapper .excluded-numbers-wrapper{
  border-radius: 21px 0 0 0;
}
.customer-blocks-popup .blocks-list-wrapper .search .back,
.customer-watch-wrapper .search .back{
  display: flex;
  color: white;
}
.customer-blocks-popup .desktop-grid [data-field="number"],
.customer-blocks-popup .desktop-grid [data-field="active"],
.customer-blocks-popup .desktop-grid [data-field="status"],
.customer-blocks-popup .desktop-grid [data-field="call_type"]{
  width: 15%;
}
.customer-blocks-popup .desktop-grid [data-field="note"]{
  width: 30%;
}
.customer-blocks-popup .desktop-grid [data-field="edit"],
.customer-blocks-popup .desktop-grid [data-field="delete"]{
  width: 5%;
  cursor: pointer;
}
.simehadrin .customer-blocks-popup .desktop-grid [data-field="edit"] ellipse,
.simehadrin .customer-blocks-popup .desktop-grid [data-field="delete"] ellipse{
  fill: #206881;
}
.customer-blocks-popup .blocks-list-wrapper .search .back .text,
.customer-watch-wrapper .search .back .text{
  margin-left: 5px;
}
.customer-blocks-popup .blocks-list-wrapper .grid-wrapper{
  margin-top: -28px;
}
.customer-blocks-popup .blocks-list-wrapper .controls .text strong,
.customer-watch-wrapper .controls .text strong{
  margin-left: 5px;
}
.customer-blocks-information-view .switch-entity .arrow{
  border-color: black;
}
.customer-blocks-popup .blocks-list-wrapper .controls > .text,
.customer-blocks-popup .blocks-list-wrapper .controls .switch-type.line .text,
.customer-watch-wrapper .controls > .text,
.customer-watch-wrapper .controls .switch-type.line .text{
  color: white;
}
.switch-type.customer .text{
  color: #FFC700;
}
.simehadrin .switch-type.customer .text{
  color: #206881;
}
.switch-type.customer circle{
  fill: #FFC700;
}
.simehadrin .switch-type.customer circle{
  fill: #206881;
}
.switch-type{
  display: flex;
  align-items: center;
  background-color: #FFC700;
  width: fit-content;
  padding: 3px 7px 3px 12px;
  border-radius: 32px;
  margin-left: 16px;
  cursor: pointer;
}
.simehadrin .switch-type{
  background-color: #206881;
}
.switch-entity .lines-list{
  position: absolute;
  background-color: #888888;
  color: white;
  top: 29px;
  left: -5px;
  width: 100%;
  border-radius: 14px;
  padding: 5px;
  z-index: 999;
}
.switch-type.customer{
  background-color: white;
}
.switch-entity{
  display: flex;
  align-items: center;
  position: relative;
}
.customer-blocks-information-view .switch-type.customer .text,
.customer-blocks-information-view .switch-type.line .text{
  color: white;
}
.customer-blocks-information-view .switch-entity .text{
  color: black;
}
.customer-blocks-popup .switch-entity .text,
.customer-watch-wrapper .switch-entity .text{
  margin-top: 23px;
  color: white;
}
.customer-blocks-information-view .switch-type.customer circle{
  fill: white;
}
.customer-blocks-information-view .switch-type.customer{
  background-color: #A0A0A0;
}
.customer-blocks-popup .blocks-list-wrapper .search-box{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 23px 0 11px 0;
}
.customer-blocks-popup .blocks-list-wrapper .line.selected{
  display: none;
}
.customer-blocks-popup .blocks-list-wrapper .search-box input{
  background: none;
  border: none;
  width: 150px;
  display: block;
  margin-left: 5px;
  color: #BABABA;
}
.customer-blocks-popup .blocks-list-wrapper .search-box .search-icon{
  display: block;
  margin-top: -3px;
}
.customer-blocks-popup .blocks-list-wrapper .search-box input::placeholder {
  color: #BABABA;
}
.switch-entity .text{
  margin-left: 10px;
  color: #FFC700;
}
.simehadrin .switch-entity .text{
  margin-left: 10px;
  color: #206881;
}
.switch-entity .arrow{
  border-color: white;
}
.switch-wrapper{
  margin-top: 10px;
  display: flex;
}
.switch-type.line .circle{
  margin-left: 6px;
}
.switch-type.customer .circle{
  margin-right: 6px;
}
.button-add,
.button-add-belongs,
.button-remove-belongs,
.button-import,
.button-delete{
  padding: 10px 15px;
  width: fit-content;
  border-radius: 21px;
  background-color: white;
  cursor: pointer;
  margin-left: 8px;
  height: fit-content;
}
.button-add-belongs{
  background-color: #FFC700;
  color: white;
}
.simehadrin .button-add-belongs{
  background-color: #206881;
}
.button-remove-belongs{
  background-color: #EC948D;
  color: white;
}
.button-add{
  color: #FFC700;
  border: 1px solid #FFC700;
}
.simehadrin .button-add{
  color: #206881;
  border: 1px solid #206881;
}
.button-import{
  color: #888888;
  border: 1px solid #888888;
  padding: 0;
  display: flex;
}
.button-import .main,
.button-import .extension{
  padding: 8px;
  display: flex;
  align-items: center;
  border-radius: 21px;
  justify-content: center;
}
.button-import.hover .main{
  background-color: #888888;
  color: white;
  width: 180px;
  cursor: pointer;
}
.button-import.hover .main svg path{
  stroke: white;
}
.button-import.hover a.main{
  text-decoration: none;
}
.button-import .main.right svg{
  margin-right: 5px;
}
.button-import .main svg{
  margin-left: 5px;
}
.button-import img{
  margin-left: 8px;
}
.button-delete{
  color: #B42318;
  border: 1px solid #B42318;
}
.customer-blocks-information-view .button-add{
  padding: 6px 12px;
  height: fit-content;
  width: 30%;
}
.desktop-grid-wrapper .controls{
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.desktop-grid .header,
.desktop-grid .body .row{
  display: flex;
  align-items: center;
  margin: 0 15px;
  padding: 10px 0;
  border-bottom: 1.5px solid #DDDDDD;
}
.desktop-grid .body .row{
  padding: 7px 0;
}
.desktop-grid .no-rows{
  padding: 10px 15px;
  text-align: center;
}
.desktop-grid .body .expansion{
  padding: 12px 15px;
  border-radius: 21px;
  border: 2px solid #F5F5F5;
}
.desktop-grid .row-wrapper.expanded{
  background-color: #DDDDDD;
  border-radius: 21px;
}
.desktop-grid .row-wrapper.expanded .show-device.button ellipse{
  fill: white;
}
.desktop-grid .row-wrapper.expanded .show-device.button circle{
  stroke: white;
}
.desktop-grid .row-wrapper.expanded .show-device.button path{
  fill: #DDDDDD;
}
.desktop-grid .body .device-wrapper{
  display: flex;
  width: 80%;
}
.desktop-grid .body .device-wrapper div{
  width: 25%;
}
.customers-wrapper .desktop-grid .header .column,
.customers-wrapper .desktop-grid .body .row .item{
  width: 30%;
}
.customers-wrapper .desktop-grid .header .column[data-field="view"],
.customers-wrapper .desktop-grid .header .column[data-field="delete"],
.customers-wrapper .desktop-grid .body .row .item[data-field="view"],
.customers-wrapper .desktop-grid .body .row .item[data-field="delete"]{
  width: 5%;
}
.customer-information-popup .desktop-grid .header .column,
.customer-information-popup .desktop-grid .body .row .item{
  width: 20%;
}
.category-information-desktop .desktop-grid .header .column,
.category-information-desktop .desktop-grid .body .row .item{
  width: 15%;
}
.category-information-desktop .desktop-grid .header .column[data-field="edit"],
.category-information-desktop .desktop-grid .header .column[data-field="delete"],
.category-information-desktop .desktop-grid .body .row .item[data-field="edit"],
.category-information-desktop .desktop-grid .body .row .item[data-field="delete"]{
  width: 5%;
}
.category-information-desktop .desktop-grid-container,
.categories-wrapper .desktop-grid-container{
  display: flex;
}
.category-information-desktop .desktop-grid-container .desktop-grid,
.categories-wrapper .desktop-grid-container .desktop-grid{
  width: 100%;
}
.category-information-desktop .desktop-grid .header .column[data-field="note"],
.category-information-desktop .desktop-grid .body .row .item[data-field="note"]{
  width: 30%;
}
.category-information-desktop .title-wrapper.secondary .text{
  margin-left: 5px;
}
.category-information-desktop .title-wrapper.secondary{
  justify-content: flex-start;
  margin-bottom: 16px;
}
.category-information-desktop .title-wrapper.secondary .text.main{
  font-weight: bold;
}
.category-information-desktop .search-form{
  top: 0;
}
.category-information-desktop .desktop-grid .body .row.highlighted{
  background-color: #FFC700;
  margin: 0;
  padding: 7px 15px;
  border-radius: 21px;
}
.simehadrin .category-information-desktop .desktop-grid .body .row.highlighted{
  background-color: #206881;
}
.category-information-mobile{
  padding: 0 17px;
}
.category-information-mobile .grid-wrapper .controls,
.customer-blocks-popup .grid-wrapper .controls{
  margin-right: 0;
}
.category-information-mobile .button-add{
  width: 112px;
  padding: 7px 15px;
}
.category-information-mobile .mobile-grid .expansion .block-actions{
  background-color: #FFC700;
  display: flex;
  justify-content: space-between;
  padding: 8px 10px;
  border-radius: 17px;
}
.simehadrin .category-information-mobile .mobile-grid .expansion .block-actions{
  background-color: #206881;
  color: #fff;
}
.category-information-mobile .mobile-grid .expansion .block-actions.hidden{
  display: none;
}
.category-information-mobile .mobile-grid .expansion .block-actions .actions{
  display: flex;
}
.category-information-mobile .mobile-grid .expansion .block-actions .actions .edit,
.category-information-mobile .mobile-grid .expansion .block-actions .actions .delete{
  display: flex;
  align-items: center;
}
.category-information-mobile .mobile-grid .expansion .block-actions .actions .edit .text,
.category-information-mobile .mobile-grid .expansion .block-actions .actions .delete .text{
  margin-left: 6px;
}
.category-information-mobile .mobile-grid .expansion .block-actions .actions .edit{
  margin-left: 15px;
}
.category-information-mobile .title-wrapper{
  display: block;
}
.category-information-mobile .title-wrapper .title{
  font-weight: unset;
}
.category-information-mobile .title-wrapper .text.description{
  display: flex;
  margin-bottom: 5px;
}
.category-information-mobile .title-wrapper .text.details{
  margin-bottom: 20px;
  display: flex;
}
.category-information-mobile .title-wrapper .text.details div{
  margin-left: 5px;
}
.category-information-mobile .title-wrapper .title .current{
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 13px;
}
.category-information-mobile .title-wrapper .title .back{
  display: flex;
  align-items: center;
  margin-bottom: 11px;
}
.category-information-mobile .title-wrapper .title .back img{
  margin-left: 10px;
}
.category-information-mobile .mobile-grid .body .row.expanded{
  margin-bottom: 0px;
  border-bottom: none;
}
.new.popup .title .text-wrapper .text{
  color: #FFC700;
  font-weight: 400;
}
.simehadrin .new.popup .title .text-wrapper .text{
  color: #206881;
}
.new.popup div[role="presentation"] .MuiPaper-root{
  width: 308px;
  padding: 8px 16px 18px;
}
.new.popup input{
  width: calc(100% - 4px);
  margin-bottom: 20px;
}
.new.popup .title{
  margin-bottom: 15px;
}
.new.popup input::placeholder{
  color: #BABABA;
}
.new.popup .switch-type{
  color: white;
  margin-left: 5px;
  width: 78px;
  justify-content: space-between;
}
.new.popup .switch-type.main{
  background-color: #C6C6C6;
}
.new.popup .switch-type.main .text{
  margin-left: 5px;
  margin-right: unset;
}
.new.popup .switch-type .text{
  margin-left: unset;
  margin-right: 5px;
}
.new.popup .controls{
  justify-content: flex-end;
}
.new.popup .switch-wrapper{
  align-items: center;
}
.new.block.popup .switch-wrapper{
  margin-bottom: 10px;
}
.new.block.popup .switch-wrapper .text{
  margin-left: 5px;
}
.customer-information-popup .desktop-grid .header .column[data-field="view_details"],
.customer-information-popup .desktop-grid .body .row .item[data-field="view_details"]{
  width: 10%;
}
.customer-information-popup .desktop-grid .header .column[data-field="view_watch_profile"],
.customer-information-popup .desktop-grid .body .row .item[data-field="view_watch_profile"]{
  width: 10%;
}
.desktop-grid{
  background-color: #F5F5F5;
  border-radius: 21px;
}
.desktop-grid .item .button{
  display: block;
}
[data-field="view"].item .button circle{
  stroke: #FFC700;
}
[data-field="view"].item .button ellipse,
[data-field="delete"].item .button ellipse{
  fill: #FFC700;
}
.simehadrin [data-field="view"].item .button circle{
  stroke: #206881;
}
.simehadrin [data-field="view"].item .button ellipse,
.simehadrin [data-field="delete"].item .button ellipse{
  fill: #206881;
}
.customers-wrapper .desktop-grid .item .button-wrapper{
  display: none;
  position: relative;
} 
.customers-wrapper .desktop-grid .body .row .item .button-wrapper .description{
  background-color: #888888;
  border-radius: 3px;
  color: white;
  width: fit-content;
  padding: 2px 5px;
  margin: 5px auto;
  position: absolute;
  left: 8px;
  display: none;
}
.customers-wrapper .desktop-grid .body .row .item .button-wrapper svg{
  margin: auto;
}
.customers-wrapper .desktop-grid .item .button:hover ellipse{
  fill: black;
}
.customers-wrapper .desktop-grid .item .button:hover circle{
  stroke: black;
}
.main-wrapper{
  position: relative;
}
.inner-dialog{
  position: absolute;
  top: 0;
  height: calc(100% - 120px);
  width: calc(100% - 410px);
  background-color: rgba(255, 255, 255, 0.72);
  padding: 60px 205px;
}
.customers-wrapper .desktop-grid .row{
  min-height: 30px;
}
.customers-wrapper .desktop-grid .row:hover .item .button-wrapper{
  display: block;
}
.customers-wrapper .desktop-grid .body .row .item .button-wrapper:hover .description{
  display: block;
}
.desktop-grid .selection-box{
  position: absolute;
  right: -32px;
  top: 8px;
}
.desktop-grid .selection-box .mark{
  position: absolute;
  left: 6px;
  top: 3px;
}
.desktop-grid .row-wrapper{
  position: relative;
}
.desktop-grid .row-wrapper:last-child .row{
  border-bottom: unset;
}
.desktop-grid .row-wrapper.selected{
  border: 1px solid #B42318;
  border-radius: 21px;
  background-color: white;
}
.desktop-grid-wrapper{
  position: relative;
}
.desktop-grid-wrapper .expansions-wrapper{
  position: absolute;
  top: 43px;
  width: calc(100% - 30px);
  margin-right: 30px;
}
.desktop-grid-wrapper .expansions-wrapper .expansion{
  position: absolute;
  display: flex;
  width: 100%;
  background-color: #DDDDDD;
  padding: 8px 15px;
  border-radius: 21px;
  align-items: center;
}
.desktop-grid-wrapper.has-selections .expansions-wrapper .expansion{
  right: -32px;
}
.desktop-grid-wrapper .expansions-wrapper .expansion .item{
  width: 15%;
}
.desktop-grid-wrapper .expansions-wrapper .expansion .item.number input.underline{
  width: calc(100% - 15px);
}
.desktop-grid-wrapper .expansions-wrapper .expansion .item.save{
  width: 30px;
  margin-right: 15px;
}
.desktop-grid-wrapper .expansions-wrapper .expansion .item.note{
  width: calc(40% - 45px);
}
.desktop-grid-wrapper .expansions-wrapper .expansion .item .switch-type{
  background-color: #888888;
  justify-content: space-between;
}
.desktop-grid-wrapper .expansions-wrapper .expansion .item .switch-type .text{
  color: white;
  margin-right: 10px;
}
.desktop-grid-wrapper .expansions-wrapper .expansion .item .switch-type.main .text{
  margin-right: unset;
}
.desktop-grid-wrapper .expansions-wrapper .expansion .item .switch-type.main{
  background-color: #FFC700;
}
.simehadrin .desktop-grid-wrapper .expansions-wrapper .expansion .item .switch-type.main{
  background-color: #206881;
}
.desktop-grid-wrapper .expansions-wrapper .expansion .item .switch-type .text svg{
  position: relative;
    top: 2px;
}
.desktop-grid-wrapper .expansions-wrapper .expansion .item .switch-type .text path{
  stroke: white;
}
.desktop-grid-wrapper .expansions-wrapper .expansion .item.note input.underline{
  width: 100%;
}
.desktop-grid-wrapper .pagination{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: fit-content;
  position: absolute;
  left: 0;
}
.desktop-grid-wrapper .pagination *{
  color: #888888;
}
.desktop-grid-wrapper .pagination .dropdown{
  margin: 0 10px;
}
.desktop-grid-wrapper .rows-per-page{
  display: flex;
  align-items: center;
}
.grid-wrapper .controls{
  margin-top: 8px;
  margin-right: 30px;
  align-items: center;
}
.categories-wrapper .grid-wrapper .controls,
.category-information-desktop .grid-wrapper .controls{
  margin-right: unset;
}
.customers-wrapper .desktop-grid-wrapper{
  width: calc(70% - 20px);
  margin-left: 20px;
}
.customer-info-dialog .MuiPaper-root{
  max-width: unset;
}
.customer-blocks-information-view .controls-wrapper{
  display: flex;
  align-items: center;
  margin-bottom: 19px;
}
.customer-blocks-information-view .expansion .block-actions,
.category-information-mobile .expansion .block-actions{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.customer-blocks-information-view .expansion .block-actions.hidden,
.category-information-mobile .expansion .block-actions.hidden{
  display: none;
}
.customer-blocks-information-view .expansion .actions img{
  width: 24px;
}
.customer-blocks-information-view .expansion .actions .text{
  margin-left: 7px;
}
.customer-blocks-information-view .expansion .actions .edit{
  margin-left: 20px;
}
.category-information-mobile .expansion,
.customer-blocks-information-view .expansion{
  background-color: #A0A0A0;
  border-radius: 20px;
  border: none;
}
.category-information-mobile .expansion .edit-form .line,
.customer-blocks-information-view .expansion .block-actions,
.customer-blocks-information-view .expansion .edit-form .line{
  padding: 7px 13px 7px 13px;
  border-radius: 20px;
  background-color: #FFC700;
  border: 0;
}
.simehadrin .category-information-mobile .expansion .edit-form .line,
.simehadrin .customer-blocks-information-view .expansion .block-actions,
.simehadrin .customer-blocks-information-view .expansion .edit-form .line{
  background-color: #206881;
}
.customer-blocks-information-view .expansion .edit-form .note-wrapper,
.category-information-mobile .expansion .edit-form .note-wrapper{
  display: flex;
  padding: 7px 13px;
}
.customer-blocks-information-view .expansion .edit-form .note-wrapper .text,
.category-information-mobile .expansion .edit-form .note-wrapper .text{
  width: 50px;
}
.customer-blocks-information-view .expansion .edit-form .note-wrapper img,
.category-information-mobile .expansion .edit-form .note-wrapper img{
  width: 25px;
}
.customer-blocks-information-view .expansion .edit-form .note-wrapper input,
.category-information-mobile .expansion .edit-form .note-wrapper input{
  width: calc(100% - 85px);
  color: white;
  border-bottom: 1px solid white;
  margin: 0 5px;
}
.customer-blocks-information-view .expansion .block-actions .item.phone{
  width: 50%;
}
.customer-blocks-information-view .expansion .block-actions .item.actions{
  display: flex;
  justify-content: flex-end;
  width: 50%;
}
.mobile-grid-wrapper .expansion .block-actions .item.actions .edit,
.mobile-grid-wrapper .expansion .block-actions .item.actions .delete{
  display: flex;
  align-items: center;
}
.simehadrin .item.actions .edit path.external{
  fill: gray;
}
.simehadrin .item.actions .delete path.external{
  stroke: gray;
}
.mobile-grid-wrapper .expansion .edit-form .line{
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.mobile-grid-wrapper .expansion .edit-form .line .input-wrapper{
  width: 50%;
}
.mobile-grid-wrapper .expansion .edit-form .line .underline{
  color: white;
  border: none;
}
.mobile-grid-wrapper .expansion .edit-form .line .extra-items{
  width: 50%;
  display: flex;
  padding-right: 17px;
  align-items: center;
}
.mobile-grid-wrapper .expansion .edit-form .line .extra-items .switch-type{
  background-color: #A0A0A0;
  color: white;
}
.mobile-grid-wrapper .expansion .edit-form .line .extra-items .switch-type.boolean{
  width: 50px;
  justify-content: space-between;
}
.mobile-grid-wrapper .expansion .edit-form .line .extra-item-wrapper:nth-of-type(1){
  width: 35%;
}
.mobile-grid-wrapper .expansion .edit-form .line .extra-item-wrapper:nth-of-type(2){
  width: 36%;
}
.mobile-grid-wrapper .expansion .edit-form .line .extra-item-wrapper .select{
  display: flex;
  align-items: center;
  color: white;
  position: relative;
}
.mobile-grid-wrapper .expansion .edit-form .line .extra-item-wrapper .select .text{
  margin-left: 5px;
}
.mobile-grid-wrapper .expansion .edit-form .line .extra-item-wrapper .select .arrow{
  border-color: white;
}
.mobile-grid-wrapper .expansion .edit-form .line .extra-item-wrapper .select .lines-list,
.desktop-grid-wrapper .expansion .select .lines-list,
.popup .select .lines-list,
.add-form .select .lines-list{
  position: absolute;
  top: 0;
  border-radius: 14px;
  color: #A0A0A0;
  display: flex;
  padding: 3px 7px 3px 9px;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 25%);
  background-color: white;
}
.customer-blocks-information-view .expansion .edit-form .line .extra-item-wrapper .select .lines-list.hidden,
.category-information-mobile .expansion .edit-form .line .extra-item-wrapper .select .lines-list.hidden,
.desktop-grid-wrapper .expansion .select .lines-list.hidden,
.add-form .select .lines-list.hidden{
  display: none;
}
.category-information-desktop .grid-wrapper{
  position: relative;
}
.customer-blocks-information-view .expansion .edit-form .line .extra-item-wrapper .select .lines-list .arrow,
.category-information-mobile .expansion .edit-form .line .extra-item-wrapper .select .lines-list .arrow,
.desktop-grid-wrapper .expansion .select .lines-list .arrow,
.popup .select .lines-list .arrow,
.add-form .select .lines-list .arrow{
  border-color: #A0A0A0;
  margin: 8px 8px 0 0;
}
.add-form-wrapper{
  position: relative;
  top: 5px;
}
.add-form{
  display: flex;
  background-color: #FFC700;
  border-radius: 21px;
  padding: 7px;
  width: calc(100% - 14px);
  align-items: center;
  bottom: 0px;
  position: absolute;
}
.simehadrin .add-form{
  background-color: #206881;
}
.add-form .switch-type.text svg.circle{
  margin-top: 2px;
}
.add-form.hidden{
  display: none;
}
.add-form .item{
  width: 15%;
}
.add-form .item .select{
  color: white;
}
.add-form .item input.underline{
  width: calc(100% - 20px);
  border-color: white;
  color: white;
  direction: ltr;
}
.row [data-field="number"]{
  direction: ltr;
  text-align: right;
}
.add-form .switch-type{
  background-color: #888888;
  color: #FFC700;
  justify-content: space-between;
}
.simehadrin .add-form .switch-type{
  color: #206881;
}
.add-form .switch-type.main{
  background-color: white;
}
.add-form .switch-type circle{
  fill: #FFC700;
}
.simehadrin .add-form .switch-type circle{
  fill: #206881;
}
.add-form .switch-type path{
  stroke: #FFC700;
}
.simehadrin .add-form .switch-type path{
  stroke: #206881;
}
.add-form .item.note{
  width: calc(40% - 43px);
}
.add-form .item.save{
  width: 43px;
}
.add-form .item .button-wrapper{
  background-color: white;
  width: fit-content;
  padding: 9px 10px 4px;
  border-radius: 20px;
}
.simehadrin .add-form .item .button-wrapper svg path{
  stroke: #206881;
}
.customer-blocks-information-view .expansion .edit-form .line .switch-type.main,
.category-information-mobile .expansion .edit-form .line .switch-type.main{
  background-color: white;
  color: #FFC700;
}
.simehadrin .customer-blocks-information-view .expansion .edit-form .line .switch-type.main,
.simehadrin .category-information-mobile .expansion .edit-form .line .switch-type.main{
  background-color: white;
  color: #206881;
}
.customer-blocks-information-view .expansion .edit-form .line .switch-type .text,
.category-information-mobile .expansion .edit-form .line .switch-type .text{
  margin-left: 4px;
  margin-right: 3px;
  margin-top: 1px;
  margin-bottom: 1px;
}
.customer-blocks-information-view .expansion .edit-form .line .switch-type.boolean .text,
.category-information-mobile .expansion .edit-form .line .switch-type.boolean .text{
  margin-top: 2px;
  margin-bottom: 0;
}
.customer-blocks-information-view .expansion .edit-form .line .switch-type .text svg path,
.category-information-mobile .expansion .edit-form .line .switch-type .text svg path{
  stroke: white;
}
.customer-blocks-information-view .expansion .edit-form .line .switch-type.main .text svg path,
.category-information-mobile .expansion .edit-form .line .switch-type.main .text svg path{
  stroke: #FFC700;
}
.simehadrin .customer-blocks-information-view .expansion .edit-form .line .switch-type.main .text svg path,
.simehadrin .category-information-mobile .expansion .edit-form .line .switch-type.main .text svg path{
  stroke: #206881;
}
.customer-blocks-information-view .expansion .edit-form .line .switch-type.main circle,
.category-information-mobile .expansion .edit-form .line .switch-type.main circle{
  fill: #FFC700;
}
.simehadrin .customer-blocks-information-view .expansion .edit-form .line .switch-type.main circle,
.simehadrin .category-information-mobile .expansion .edit-form .line .switch-type.main circle{
  fill: #206881;
}
input.underline{
  background: none;
  border: none;
  border-bottom: 1px solid black;
}
input.underline::placeholder{
  color: #888888;
}
.categories-wrapper,
.category-information-desktop{
  padding: 0 30px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.title-wrapper .title{
  font-weight: bold;
}
.search-label{
  display: flex;
  align-items: center;
}
.search-label .text{
  margin-left: 10px;
  color: #BABABA;
}
.title-wrapper{
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.categories-wrapper .title-wrapper{
  margin-bottom: 20px;
}
.confirm-dialog .controls div{
  width: 116px;
}
.confirm-dialog .controls .proceed{
  border: 1px solid #FFC700;
  color: #FFC700;
  cursor: pointer;
}
.simehadrin .confirm-dialog .controls .proceed{
  border: 1px solid #206881;
  color: #206881;
}
.confirm-dialog .controls .cancel{
  background-color: #FFC700;
  color: white;
  cursor: pointer;
}
.simehadrin .confirm-dialog .controls .cancel{
  background-color: #206881;
}
.simehadrin .edit-category.button ellipse{
  fill: #206881;
}
.category-information-desktop .desktop-grid-wrapper .controls{
  margin-right: 30px;
}
.grid-wrapper .controls{
  display: flex;
}
.confirm-dialog .title{
  margin-bottom: 23px;
}
.category-information-desktop .title-wrapper.secondary{
  color: #888888;
}
.category-information-desktop .title-wrapper.hidden{
  opacity: 0;
}
.category-information-desktop .title-wrapper .title{
  font-weight: unset;
  display: flex;
}
.category-information-desktop .title-wrapper .title .current{
  font-weight: bold;
  margin-right: 5px;
}
.top-wrapper{
  position: relative;
}
.title-wrapper.hidden, .top-wrapper.hidden{
  visibility: hidden;
}
.search-form{
  position: absolute;
  display: flex;
  width: calc(100% - 30px);
  justify-content: space-between;
  background-color: #F5F5F5;
  border-radius: 21px;
  padding: 0 15px;
  top: -10px;
}

.search-form .focus-input {
  cursor: pointer;
  padding: 10px 15px;
  width: 100%;
}

.search-form .search-item.action{
  cursor: pointer;
}
.search-form.hidden{
  display: none;
}
.search-form .select{
  color: #888888;
  min-width: 85px;
}
.search-form .close,
.search-form .select{
  cursor: pointer;
}
.search-form .select .lines-list .arrow{
  margin-top: 8px;
  margin-right: 8px;
}
.search-form .select .lines-list{
  top: -5px;
  right: -4px;
  z-index: 999;
}
.search-form .search-item{
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  width: 100%;
}
.select{
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.select .lines-list{
  display: flex;
  position: absolute;
}
.select .lines-list.hidden{
  display: none;
}
.search-form .select .lines-list{
  background-color: white;
  padding: 5px;
  border-radius: 15px;
  top: 0;
}

.select .text{
  margin-left: 7px;
}
.search-form .search-item > .text{
  margin-left: 10px;
}
.search-form .close{
  width: 15px;
}

.search-form .search-button {
  cursor: pointer;
  height: fit-content;
  margin: auto;
  margin-left: -10px;
  padding: 10px 8px;
}
.search-form .close-button {
  cursor: pointer;
  height: fit-content;
  margin: auto;
  margin-right: -10px;
  padding: 10px 8px;
}
.categories-wrapper .desktop-grid .header .column, .categories-wrapper .desktop-grid .body .row .item{
  width: 12.8%;
}
.categories-wrapper .desktop-grid .header .column[data-field="view"], 
.categories-wrapper .desktop-grid .header .column[data-field="delete"], 
.categories-wrapper .desktop-grid .body .row .item[data-field="view"], 
.categories-wrapper .desktop-grid .body .row .item[data-field="delete"]{
  width: 5%;
}
.mobile.watch-wrapper .watch-content-wrapper{
  padding: 20px 0;
  margin: 0 auto 20px;
}
.mobile .watch-content-wrapper .profile-wrapper .profile-content{
  margin: 0;
}
.mobile .watch-content-wrapper .profile-wrapper{
  justify-content: center;
}
.mobile .watch-content-wrapper .profile-wrapper .day-profile .hour-wrapper{
  width: fit-content;
  padding: 6px 15px;
}
.mobile .watch-content-wrapper .profile-wrapper .day-profile{
  justify-content: flex-start;
}
.mobile .watch-content-wrapper .profile-wrapper .day-profile .column,
.mobile .watch-content-wrapper .profile-wrapper .day-profile .column.right{
  width: unset;
}
.mobile .watch-content-wrapper .profile-wrapper .days-title .days .day{
  padding: 5px 10px;
  font-size: 18px;
  justify-content: space-between;
}
.mobile .watch-content-wrapper .profile-wrapper .days-title .days{
  justify-content: space-between;
}
.mobile.watch-wrapper .select{
  margin: 0 15px;
}
.mobile .excluded-numbers-wrapper{
  position: fixed;
  top: 295px;
  height: 100%;
  border-radius: 23px 23px 0px 0px;
  background-color: #F5F5F5;
  filter: drop-shadow(1px -1px 10px rgba(0, 0, 0, 0.15));
}
.mobile .watch-content-wrapper .profile-wrapper .profile-content-wrapper{
  width: 100%;
}
.remove-customer-popup{
  width: 300px;
  margin: auto;
  border: 2px solid #FFC700;
  border-radius: 21px;
  padding: 25px;
  background: white;
}
.simehadrin .remove-customer-popup{
  border: 2px solid #206881;
}
@media screen and (max-width: 1650px) {
  .customer-watch-wrapper .watch-content-wrapper{
    padding: 40px 20px;
    width: calc(80% - 40px);
  }
}
@media screen and (max-width: 1450px) {
  .watch-wrapper .watch-content{
    display: block;
  }
  .watch-content-wrapper .status-wrapper{
    margin-bottom: 20px;
  }
  .inner-dialog {
      width: calc(100% - 100px);
      padding: 60px 50px;
  }
}
@media screen and (max-width: 960px) {
    *{
      font-size: 16px;
    }
    .categories-wrapper{
      padding: 0 17px;
      margin-right: unset;
    }
    .notice-message.categories{
      margin: 17px;
    }
    .login-decoration-wrapper,
    .login-page-decoration{
      display: none;
    }
    .login-form-wrapper{
      width: 100%;
    }
    .login-form{
      width: 90%;
    }
    .dashboard-wrapper .side{
      display: none;
    }
    .dashboard-wrapper .main .stats-wrapper{
      display: block;
    }
    .dashboard-wrapper .main{
      width: 100%;
    }
    .dashboard-wrapper .description{
      margin: 24px 30px;
    }
    .dashboard-wrapper .blocking-policy-wrapper{
      margin: 24px 30px;
    }
    .watch-day-wrapper{
      margin-bottom: 20px;
    }
    .watch-day-title{
        margin-bottom: 10px;
        cursor: pointer;
    }
    .watch-day-title div{
        margin-left: 5px;
    }
    .customers-wrapper {
      padding: 20px 17px;
    }
    .customers-wrapper .grid-wrapper{
      display: block;
    }
    .categories-wrapper .search-wrapper{
      height: fit-content;
      border-radius: 21px;
      background-color: #F5F5F5;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      width: 30%;
    }
    .customers-wrapper .search-wrapper,
    .categories-wrapper .search-wrapper,
    .category-information-mobile .search-wrapper,
    .customer-blocks-information-view .search-wrapper{
      width: 100%;
      margin-bottom: 10px;
      padding: 6px 0;
    }
    .customer-blocks-information-view .line.selected{
      display: flex;
      align-items: center;
    }
    .customer-blocks-information-view .line.selected .text{
      color: black;
    }
    .customer-blocks-information-view .line{
      margin-bottom: 6px;
    }
    .customer-blocks-information-view .line.selected .arrow{
      border-color: black;
      margin-top: 7px;
    }
    .customer-blocks-information-view .lines-list{
      padding: 3px 7px 3px 9px;
      box-shadow: 0px 2px 8px rgb(0 0 0 / 25%);
      background-color: white;
      color: black;
    }
    .customer-blocks-information-view .search-wrapper,
    .category-information-mobile .search-wrapper{
      width: calc( 70% - 46px);
      padding: 7px 14px 6px 8px;
    }
    .category-information-mobile .search-wrapper{
      width: calc(100% - 120px);
    }
    .customer-blocks-information-view .mobile-grid .body .row.expanded{
      margin-bottom: 0;
    }
    .customers-wrapper *{
      font-size: 16px;
    }
    .customers-wrapper .title{
      font-size: 18px;
    }
    .switch-entity .lines-list{
      top: 0;
    }
    .categories-wrapper .search-wrapper .MuiOutlinedInput-root.MuiInputBase-root div[role="button"]{
      padding-right: 13px;
      padding-left: 45px;
      width: calc(100% - 58px);
    }
    .categories-wrapper .search-wrapper .MuiOutlinedInput-root svg{
      left: 7px;
      right: unset;
    }
    .categories-wrapper .search-wrapper .field{
      min-width: 115px;
      width: 30%;
    }
    .categories-wrapper .search-wrapper .search{
      border: 0;
      border-bottom: 0.5px solid #000000;
      background-color: #F5F5F5;
      display: block;
      margin-left: 12px;
      width: 65%; 
    }
    .categories-wrapper .search-wrapper div.search{
      border: 0;
    }
    .watch-wrapper{
      display: block;
    }
    .watch-wrapper .watch-content-wrapper{
      padding: 5px 10px;
      width: calc(100% - 60px);
    }
    .watch-wrapper .excluded-numbers-wrapper{
      padding: 10px;
      width: calc(100% - 20px);
    }
    .navigation-bar-mobile .logo-link .logo{
      max-height: 64px;
    }
}
@media screen and (max-width: 450px) {
  .remove-customer-popup{
    width: unset;
  }
    div.content-wrapper .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell{
        overflow: scroll;
    }

    div.content-wrapper .MuiDataGrid-cellContent{
        overflow: scroll;
    }
    div.content-wrapper .MuiDataGrid-columnHeaders{
        overflow: scroll;
    }
    .mobile-grid .main-column,
    .customer-blocks-information-view .expansion .block-actions .item.phone,
    .customer-blocks-information-view .expansion .edit-form .line .input-wrapper{
      width: 35%;
    }
    .mobile-grid .extra-columns,
    .customer-blocks-information-view .expansion .block-actions .item.actions,
    .customer-blocks-information-view .expansion .edit-form .line .extra-items{
      width: 65%;
    }
    .customer-blocks-information-view .expansion .edit-form .line .extra-items{
      margin-right: 0;
      padding-right: 0;
    }
    .customer-blocks-information-view .button-add{
      font-size: 16px;
    }
    .remove-customer-popup .controls div{
      width: calc(50% - 5px);
    }
    .notice-message img{
      display: none;
    }
    .mobile-grid-wrapper .expansion .edit-form .line .input-wrapper{
      width: 35%;
    }
    .mobile-grid-wrapper .expansion .edit-form .line .extra-items{
      width: 65%;
    }
    .mobile-grid-wrapper .expansion .edit-form .line .extra-items .switch-type{
      padding: 3px;
      margin: 0;
    }
    .mobile-grid-wrapper .expansion .edit-form .line .extra-item-wrapper:nth-of-type(1){
      width: 33.3%;
    }
    .mobile-grid-wrapper .expansion .edit-form .line .extra-item-wrapper:nth-of-type(2){
      width: 33.3%;
    }
    .mobile-grid-wrapper .expansion .edit-form .line{
      width: calc(100% - 26px);
    }
    .mobile-grid-wrapper .expansion .edit-form .line .extra-items{
      padding-right: 0;
    }
    .mobile-grid .header .column, .mobile-grid .body .row .item{
      padding-right: 0;
    }
    .mobile-grid-wrapper .expansion .edit-form .line .underline{
      width: calc(100% - 10px);
    }
    .new.popup .switch-type.main .text{
      max-width: calc(100% - 22px);
    }
    .new.popup .switch-type{
      width: 70px;
    }
    .new.block.popup .switch-type{
      width: 80px;
    }
    .category-information-mobile .button-add{
      width: 134px;
    }
}
@media screen and (max-width: 280px) {
  .dashboard-wrapper .blocking-policy .switch{
    padding: 4px 20px;
  }
}